import axios, { AxiosResponse } from "axios";
import { CLIENT_NAME, IS_BROKER_PORTAL, PLATFORM_TYPE } from "config";
import {
  concatenateKeyValuePairs,
  createAxiosInstanceWithAccessToken,
  createAxiosInstanceWithApiKey,
} from "utils/utils";

const BASE_URL = process.env.REACT_APP_SERVER_URL + "/v1";

if (IS_BROKER_PORTAL) {
  axios.defaults.headers["Platform-Type"] = PLATFORM_TYPE.BROKER_PORTAL;
  axios.defaults.headers["Client-Name"] = CLIENT_NAME;
}

export async function createClientSiaRecordAPI(
  key: string,
  payload: any
): Promise<any> {
  const QuoteAPI = createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.post("/embedded_insurance/sia_application", payload);
}

export async function createSiaApplicationAPI(
  key: string,
  payload: any
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post("/sia/applications", payload);
}

export async function createSubmissionsIdAPI(
  key: string,
  payload: any
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post("/sia/submissions", payload);
}

export async function createPurchaseAPI(
  key: string,
  payload: any
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post("/sia/purchases", payload);
}

export async function stripeConfirmAPI(
  key: string,
  payload: StripeConfirmPayload
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post("/embedded_insurance/sia/stripe:confirm", payload);
}

export async function fetchIncompleteApplicationAPI(
  key: string,
  payload: { external_id: string; products: string[] }
): Promise<any> {
  const QuoteAPI = createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.post("/sia/applications/incomplete", payload);
}

export async function fetchAvailableProductsAPI(key: string): Promise<any> {
  const QuoteAPI = createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.get("/embedded_insurance/products");
}

export async function fetchClientSiaIdAPI(
  key: string,
  payload: any
): Promise<any> {
  const QuoteAPI = createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.post(
    "/embedded_insurance/sia_application:retrieve",
    payload
  );
}

export async function fetchFactoryFunctionsAPI(key: string): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.get(`/sia/factory_functions`);
}

export async function fetchSiaApplicationAPI(
  key: string,
  applicationId: string
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.get(`/sia/applications/${applicationId}`);
}

export async function generateQuotesAPI(
  key: string,
  submission_id: string,
  regenerate?: boolean
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.get(
    `/sia/submissions/${submission_id}/generate-quotes?regenerate=${regenerate}`
  );
}

export async function updateClientSiaLatestConfigAPI(
  key: string,
  clientSiaId: any,
  payload: any
): Promise<any> {
  const QuoteAPI = createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.put(
    `/embedded_insurance/sia_application/${clientSiaId}`,
    payload
  );
}

export async function updateSiaApplicationAPI(
  key: string,
  applicationId: string,
  payload: any
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken("updateSiaApp")
    : createAxiosInstanceWithApiKey(key, true, "updateSiaApp");
  return await QuoteAPI.put(`/sia/applications/${applicationId}`, payload);
}

export async function createPresignedUrls(
  key: string,
  payload: {for_id: string, for_type: string; files: any[]}
): Promise<AxiosResponse<StereDocument[]>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key, true);
  return await QuoteAPI.post(`/sia/generate-S3-presigned-urls`, payload);
}

export async function deleteDocumentsFromS3(
  key: string,
  payload: {paths: string[]}
): Promise<AxiosResponse<DeletedS3Documents>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key, true);
  return await QuoteAPI.delete(`/sia/delete-S3-documents`, {data: payload});
}

export async function fireWebhookEventAPI(key: string, payload: any) {
  const QuoteAPI = createAxiosInstanceWithApiKey(key, false);
  return await QuoteAPI.post(`/webhooks/embedded_insurance`, payload);
}

export async function fetchSubmissionsData(
  submission_id: string,
  account_id: string
): Promise<any> {
  return await axios.get(
    `${BASE_URL}/sia/submission/${submission_id}/get-quotations/${account_id}`
  );
}

export async function generateProposalDocForQuote(
  quote_id: string,
  account_id: string,
  declarations: ProposalDeclaration[]
): Promise<any> {
  return await axios.post(`${BASE_URL}/sia/quote/proposal-document`, {
    quote_id,
    account_id,
    declarations,
  });
}

export async function generatePdfForQuote(
  quote_id: string,
  account_id: string
): Promise<AxiosResponse<StereDocument>> {
  return await axios.post(`${BASE_URL}/sia/quote/pdf-document`, {
    quote_id,
    account_id,
  });
}

export async function fetchSubmissionsWithFiltersAPI(
  filterProps: FilterParams
): Promise<any> {
  const queryParams = concatenateKeyValuePairs(filterProps);
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.get(`/sia/submissions?${queryParams}`);
}

export async function fetchSubmissionAPI(
  submissionId: string, key?: string
): Promise<AxiosResponse<Submission>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);
  return await QuoteAPI.get(`/sia/submissions/${submissionId}`);
}

export async function fetchApplicationsWithFiltersAPI(
  filterProps: FilterParams
): Promise<any> {
  const queryParams = concatenateKeyValuePairs(filterProps);
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.get(`/sia/applications?${queryParams}`);
}

export async function fetchPoliciesWithFiltersAPI(
  filterProps: FilterParams
): Promise<AxiosResponse<{ meta: MetaData; data: Policy[] }>> {
  const queryParams = concatenateKeyValuePairs(filterProps);
  const QuoteAPI = createAxiosInstanceWithAccessToken();
  return await QuoteAPI.get(`/sia/policies?${queryParams}`);
}

export const bindQuote = async (
  clientAPIKey: string,
  quoteId: string
): Promise<AxiosResponse> => {
  const QuoteAPI = createAxiosInstanceWithApiKey(clientAPIKey);
  return await QuoteAPI.post(`/sia/quotes/${quoteId}/bind`);
};

export async function createPurchase(
  key: string,
  payload: CreatePurchasePayload
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.post("/sia/create_purchase", payload);
}

export async function updatePurchaseAPI(
  key: string,
  purchaseId: string,
  payload: UpdatePurchasePayload
): Promise<any> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.put(`/sia/purchases/${purchaseId}`, payload);
}

export async function getPurchaseAPI(
  purchaseId: string,
  accountId: string
): Promise<AxiosResponse<PurchaseResponse>> {
  return await axios.get(
    `${BASE_URL}/sia/purchases/${purchaseId}/${accountId}`
  );
}

export async function getPolicyAPI(
  key: string,
  policyId: string
): Promise<AxiosResponse<Policy>> {
  const QuoteAPI = IS_BROKER_PORTAL
    ? createAxiosInstanceWithAccessToken()
    : createAxiosInstanceWithApiKey(key);

  return await QuoteAPI.get(`/sia/policies/${policyId}`);
}
