import axios, { AxiosInstance } from "axios";
import dayjs from "dayjs";

import { getRefreshToken } from "api/auth/auth";
import {
  CLIENT_NAME,
  COUNTRY,
  DOMAIN_NAME,
  IS_BROKER_PORTAL,
  FAIRFAX,
  LIBERSEGUROS,
  PORTAL_NAME,
  PLATFORM_TYPE,
} from "config";
import { errorStatusCodes } from "constants/common";
import { IframePostMessage } from "customTypes/Webhooks";
import { setAccessToken, setIdToken, signOut } from "store/features/authSlice";
import { setErrorAndShowExitBtn } from "store/features/clientSlice";
import { store } from "store/store";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { productNameMappings } from "components/Quote/sections/utils";
import { ClientProduct } from "customTypes/ClientProducts";

export const API_VERSION = "v1";
let controllerMap = {};

export const getPrefillData = (): any => {
  return JSON.parse(localStorage.getItem("prefillData") ?? "{}");
};

export const setPrefillData = (value: Partial<any>): void => {
  localStorage.setItem(
    "prefillData",
    JSON.stringify({ ...getPrefillData(), ...value })
  );
};

export const sendMessageToClient = (
  payload: IframePostMessage,
  clientUrl: string
) => {
  window.parent?.postMessage(payload, clientUrl);
};

export function createAxiosInstanceWithApiKey(
  key: string,
  includeVersionInBaseURL: boolean = true,
  abortControllerName?: string
): AxiosInstance {
  if (abortControllerName) {
    controllerMap[abortControllerName] = new AbortController();
  }
  const instance = axios.create();

  instance.interceptors.request.use((config) => {
    if (IS_BROKER_PORTAL) {
      config.headers.set("platform-type", PLATFORM_TYPE.BROKER_PORTAL);
      config.headers.set("client-name", CLIENT_NAME);
    }
    if (abortControllerName) {
      config.signal = controllerMap[abortControllerName].signal;
    }
    config.headers.set("X-API-KEY", key);
    const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
    if (accessToken) {
      config.headers.set("X-STERE-USER-TOKEN", accessToken);
      config.headers.set("X-STERE-DOMAIN", DOMAIN_NAME);
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response && errorStatusCodes.includes(error.response.status)) {
        store?.dispatch(setErrorAndShowExitBtn(true));
        sendMessageToClient(
          {
            type: "FAILURE",
            details: {
              section:
                store?.getState()?.stepper?.currentStepLabel,
            },
          },
          store?.getState()?.client?.clientUrl
        );
      }
      return Promise.reject(error);
    }
  );

  instance.defaults.baseURL = `${process.env.REACT_APP_SERVER_URL +
    (includeVersionInBaseURL ? `/${API_VERSION}` : "")
    }`;
  return instance;
}

export function createAxiosInstanceWithAccessToken(
  abortControllerName?: string
): AxiosInstance {
  const instance = axios.create();
  const accessToken = store?.getState()?.auth?.accessToken;

  if (abortControllerName) {
    controllerMap[abortControllerName] = new AbortController();
  }

  instance.interceptors.request.use((config) => {
    if (IS_BROKER_PORTAL) {
      config.headers.set("platform-type", PLATFORM_TYPE.BROKER_PORTAL);
      config.headers.set("client-name", CLIENT_NAME);
    }

    if (abortControllerName) {
      config.signal = controllerMap[abortControllerName].signal;
    }
    if (accessToken) {
      config.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    const accessTokenV2 = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
    if (accessTokenV2) {
      config.headers.set("X-STERE-USER-TOKEN", accessTokenV2);
      config.headers.set("X-STERE-DOMAIN", DOMAIN_NAME);
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response.status === 401 ||
        error.response.data?.detail === "Cognito user not found"
      ) {
        if (originalRequest._retry) {
          store?.dispatch(signOut());
          window?.location?.replace("/login");
        } else {
          originalRequest._retry = true;
          const IS_USER_AUTH_V2 = !!getFromLocalStorage(LSKeys.ACCESS_TOKEN);
          try {
            if (!IS_USER_AUTH_V2) {
              // TODO: can get rid of the below code altogether once V2 changes are deployed and completely tested
              const resp = await getRefreshToken({
                refresh_token: store?.getState()?.auth?.refreshToken,
                id_token: store?.getState()?.auth?.idToken,
              });
              const newAccessToken = resp?.data?.results?.access_token;
              const newIdToken = resp?.data?.results?.id_token;
              store?.dispatch(setAccessToken(newAccessToken));
              store?.dispatch(setIdToken(newIdToken));
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              return axios(originalRequest);
            }
          } catch (err) {
            store?.dispatch(signOut());
            window?.location?.replace("/login");
          }
        }
      } else if (
        error.response &&
        errorStatusCodes.includes(error.response.status) &&
        window?.location?.pathname?.startsWith("/newQuote")
      ) {
        store?.dispatch(setErrorAndShowExitBtn(true));
        sendMessageToClient(
          {
            type: "FAILURE",
            details: {
              section:
                store?.getState()?.stepper?.currentStepLabel,
            },
          },
          store?.getState()?.client?.clientUrl
        );
      }
      return Promise.reject(error);
    }
  );

  instance.defaults.baseURL = `${process.env.REACT_APP_SERVER_URL + `/${API_VERSION}`
    }`;
  return instance;
}

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

export const getController = (name: string) => {
  return controllerMap[name];
};

export const concatenateKeyValuePairs = (
  object: Record<string, any>
): string => {
  const keyValuePairs: string[] = [];
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const value = object[key];
      keyValuePairs.push(`${key}=${value}`);
    }
  }
  return keyValuePairs.join("&");
};

export const IsAdmin = (portalAccount: PortalAccount) =>
  portalAccount?.role === "Admin";

export const IsAgent = (portalAccount: PortalAccount) =>
  portalAccount?.role === "Agent";

export const IsUser = (portalAccount: PortalAccount) =>
  portalAccount?.role === "User";

export const CountryToLanguageMapping = {
  br: "pt",
  us: "en",
  mx: "es",
  sg: "en",
  ph: "en",
};

export const CurrencyPrefixMapping = {
  br: "R$",
  us: "$",
  mx: "$",
  sg: "S$",
  ph: "₱",
};

export const CountryToCurrencyMapping = {
  br: "BRL",
  us: "USD",
  mx: "MXN",
  sg: "SGD",
  ph: "PHP",
};

export const CountryToLocalesMapping = {
  br: "pt-br",
  us: "en-us",
  mx: "es-es",
  sg: "en-sg",
  ph: "en-ph",
};

export const CurrencyPrefix = CurrencyPrefixMapping[COUNTRY];

export const formatCurrency = (
  amount: number,
  maximumFractionDigits: number = 2
) =>
  new Intl.NumberFormat(CountryToLocalesMapping[COUNTRY], {
    currency: CountryToCurrencyMapping[COUNTRY],
    maximumFractionDigits,
    minimumFractionDigits: 2,
  })?.format(amount);

export const calculateTransactionFee = (amount: number, index: number) => {
  if (index || COUNTRY === "br" || COUNTRY === "mx") {
    return 0;
  }

  const premium = Number(amount);
  const totalAmount = (premium + 0.3) / 0.971;
  return Number((totalAmount - premium).toFixed(2));
};

export const InvalidProductCombinationsMapping: { [key: string]: string[] } = {
  GL: ["BOP", "HEALTH"],
  BOP: ["GL", "HEALTH"],
  HEALTH: ["GL", "BOP"],
};

export enum PaymentProviders {
  ANDDONE = "anddone",
  STRIPE = "stripe",
  PIX = 'pix'
}

export const toDate = dayjs();

export const fromDate = dayjs().subtract(30, "day");

export const getTimestamp = () => {
  const date = new Date();
  return date.toUTCString();
};

export const commissions = [
  {
    type: "percent",
    value: 15,
    product: "PET-MEU",
  },
];

export const extractEmail = (text: string) => {
  const emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/g;
  const emails = text.match(emailRegex);
  return emails?.length ? emails[0] : "";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function regionalText(obj: any, path: string) {
  const newPath = path
    .split(".")
    .map((p) => (p.endsWith("?") ? p.slice(0, p.length - 2) : p))
    .join("?");

  return COUNTRY === "br"
    ? obj?.[newPath]?.pt ?? obj?.[newPath]?.default
    : obj?.[newPath]?.default ?? "";
}

export function hasTokenExpired(token: string) {
  const decodedToken: JwtPayload = jwtDecode(token);
  return dayjs.unix(decodedToken.exp).isBefore(dayjs());
}

export const getProductTitle = (p: ClientProduct) => {
  return COUNTRY === "mx"
    ? productNameMappings?.[p?.product?.product_identifier][
    CountryToLanguageMapping?.[COUNTRY]
    ]
    : p?.product?.product_name;
};


export const getCookie = (name) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');

  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
}

export const destroyCookies = (cookieNames) => {
  cookieNames.forEach(cookieName => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
}

// Function to format the coverage value
// If the value is a number, format it as currency
// If the value is a string, return it as is
export const formatCoverageValue = (value: any) => {
  const countryKey = CountryToLanguageMapping[COUNTRY];
  const valueTranslated = value?.[countryKey];
  const valueDefault = value?.default;

  // If valueTranslated is a number, format it as currency
  if (typeof valueTranslated === "number") {
    // If the portal is Fairfax or Libresuguros, add the currency prefix
    const currencyPrefix =
      PORTAL_NAME === FAIRFAX || PORTAL_NAME === LIBERSEGUROS
        ? `${CurrencyPrefix} `
        : "";

    // return the formatted currency
    return `${currencyPrefix}${formatCurrency(valueTranslated)}`;
  }
  // If valueTranslated is a string, return it as is
  else if (valueTranslated) {
    return valueTranslated;
  }
  // If valueDefault is a number, format it as currency
  else if (typeof valueDefault === "number") {
    const currencyPrefix =
      PORTAL_NAME === FAIRFAX || PORTAL_NAME === LIBERSEGUROS
        ? `${CurrencyPrefix} `
        : "";
    return `${currencyPrefix}${formatCurrency(valueDefault)}`;
  }
  // If valueDefault is a string, return it as is
  else {
    return valueDefault || "N/A";
  }
};
