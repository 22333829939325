import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { customStylesSelector } from "store/selectors/selectors";
import parse from 'html-react-parser';

interface NotesAlertProps {
  note: {
    heading: string;
    content?: string;
  };
}

const NotesAlert = ({ note }: NotesAlertProps) => {
  const customStyles = useSelector(customStylesSelector);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "16px",
        borderRadius: "12px",
        backgroundColor: customStyles.primaryCard.backgroundColor,
        border: `1px solid`,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <InfoOutlinedIcon
          sx={{ color: customStyles.muiTheme.palette.text.secondary }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {note.heading}
        </Typography>
      </Box>
      
      <Box 
        sx={{ 
          paddingLeft: "32px",
          '& p': {
            fontSize: "14px",
            fontWeight: 500,
            color: customStyles.muiTheme.palette.text.tertiary,
            margin: 0,
          }
        }}
      >
        {parse(note.content)}
      </Box>
    </Box>
  );
};

export default NotesAlert;