import { TranslationKeys } from "./types";

export class TranslationEn implements TranslationKeys {
  public SEARCH = "Search";
  public SETTINGS = "Settings";
  public LOGOUT = "Logout";
  // Home Navigations
  public DASHBOARD = "Dashboard";
  public QUOTES = "Quotes";
  public POLICIES = "Policies";
  public INSURANCE_PRODUCTS = "Insurance Products";
  public MY_APPOINTMENTS = "My Appointments";
  public NEW_QUOTE = "New Quote";
  public APPLICATIONS = "Applications";
  public CLAIMS = "Claims";
  public AGENTS = "Agents";
  // Dashboard Page
  public QUOTES_SENT = "Quotes Sent";
  public POLICIES_ISSUED = "Policies Issued";
  public COMMISSION_EARNED = "Estimated Commission";
  // Applications Page
  public APPLICATIONS_PAGE = {
    title: "Applications",
    desc: "Applications in progress",
    tableHeaders: {
      application_id: "Application ID",
      applicant_name: "Applicant Name",
      applicant_email: "Applicant Email",
      business_name: "Business Name",
      date: "Date",
      quote: "Quote",
      status: "Status",
      insurance: "Insurance",
      action: "Action",
    },
  };
  public AGENTS_PAGE = {
    title: "All Agents",
    inviteBtn: "Invite",
    status: "Status",
    send: "Send",
    inviteDialogHeading: "Invite agent",
    agentsTabView: {
      myAgents: "My agents",
      sentInvites: "Sent invites",
    },
  };
  // Quotes Page
  public QUOTES_PAGE = {
    title: "Quotes",
    desc: "Quotes sent to insured",
    tableHeaders: {
      quote_id: "Quote ID",
      application_id: "Application ID",
      applicant_name: "Applicant Name",
      business_name: "Business Name",
      date: "Date",
      quote: "Quote",
      status: "Status",
      insurance: "Insurance",
      action: "Action",
    },
    status: {
      quoteSent: "Quote Sent",
      quotesSent: "Quotes Sent",
      noQuoteSent: "No Quote Sent",
      paymentMade: "Payment Made",
      quotesGenerated: "Quotes Generated",
      noQuoteGenerated: "No Quotes Generated",
    },
  };
  public APPLICATION_PAGE = {
    status: {
      draft: "Draft",
      submitted: "Submitted",
    },
  };
  // Policies Page
  public POLICIES_PAGE = {
    title: "Policies",
    desc: "Policies Issued",
    tableHeaders: {
      policy_num: "Policy Number",
      policy_holder: "Policy Holder",
      insurance: "Insurance",
      insurer: "Insurer",
      commission: "Commission",
      renewal_date: "Renewal Date",
      status: "Status",
      action: "Action",
    },
    status: {
      active: "Active",
    },
  };
  // Insurance Products Page
  public I_PRODUCTS_DESCRIPTION_PRIMARY = "Insurance products available for quoting";
  // Common
  public BACK = "Back";
  public NEXT = "Next";
  public CONTINUE = "Continue";
  public COMPLETE = "Complete";
  public COMING_SOON = "Coming Soon...";
  public BACK_TO_DASHBOARD = "Back to Dashboard";
  public CANCEL = "Cancel";
  public EXIT = "Exit";
  public SAVE = "Save";
  public PAYMENT_FREQUENCY_MONTHLY = "Monthly";
  public PAYMENT_FREQUENCY_YEARLY = "Yearly";
  public FREQUENCY_MONTH = "month";
  public FREQUENCY_YEAR = "year";
  // Loader
  public GLOBAL_LOADER_MESSAGE = "Setting things up for you...";
  public FETCHING_APPLICATION = "Fetching your application...";
  public LOADING_DOCUMENT_FOR_SIGN = "Loading documents for sign ...";
  public FETCHING_DOCU_SIGN_STATUS = "Fetching document sign status...";
  public GETTING_CONFIRMATION = "Getting confirmation...";
  public LOADING_PAYMENT_WIDGET = "Loading payment widget...";
  public FETCHING_PRODUCTS = "Fetching all products...";
  public FETCHING_PLANS = "Finding the best suited plans. We appreciate your patience...";
  public SUBMITTING_RESPONSE = "Submitting your response...";
  public FETCHING_QUOTES = "Fetching your quotes...";
  // Register page
  public REGISTER = "Register";
  public EnterDetails = "Please enter your details to continue";
  public UnableToRegisterUser = "Unable to register user!";
  // SignIn page
  public SIGN_IN = "Sign In";
  public SIGN_IN_MESSAGE = "Please enter you email and password";
  public SIGN_IN_MESSAGE_V2 = "Please enter you email";
  public OTP_MESSAGE = "Please enter the One-time password sent to your email";
  public REMEMBER_ME = "Remember Me";
  public FORGOT_PASSWORD = "Forgot Password?";
  public SIGN_IN_BUTTON_TEXT = "Sign In";
  public EMAIL_LABEL = "Email";
  public FULL_NAME_LABEL = "Full Name";
  public EMAIL_REQUIRED = "Email is required";
  public INVALID_EMAIL = "Invalid email address";
  public PASSWORD_LABEL = "Password";
  public PASSWORD_REQUIRED = "Password is required";
  public FULL_NAME_REQUIRED = "Full name is required";
  public INVALID_PASSWORD = "Password must be at least 8 characters";
  public FORGOT_PASSWORD_MESSAGE = "Enter your registered email to set a new password";
  public RECOVER_PASSWORD = "Recover Password";
  public PASSWORD_CREATED = "Password created";
  public PASSWORD_CREATED_DESCRIPTION_PRIMARY = "Your password has been created successfully";
  public CREATE_PASSWORD = "Create Password";
  public CREATE_NEW_PASSWORD = "Create New Password";
  public CREATE_NEW_PASSWORD_DESCRIPTION_PRIMARY = "Please choose a new password";
  // Stepper
  public Start = "Start";
  public Knockout = "Knockout";
  public Application = "Application";
  public Quote = "Quote & Summary";
  public Payment = "Payment";
  public Confirmation = "Confirmation";
  // Quote Start Page
  public START_PAGE_DESCRIPTION_PRIMARY = "Get the best price with us, instantly!";
  public START_PAGE_DESCRIPTION_SECONDARY = "Please select one or more products to start your application.";
  public START_PAGE_TABS = {
    PERSONAL: "Personal",
    BUSINESS: "Business",
  };

  // Customer details page
  public CustomerDetails = "Customer Details";
  public CUSTOMER_DETAILS_PAGE_DESCRIPTION_PRIMARY = "Customer Details";
  public CUSTOMER_DETAILS_PAGE_DESCRIPTION_SECONDARY = "Enter Customer";
  public CUSTOMER_DETAILS_FORM_EMAIL_LABEL = "Enter the Customer's email";

  // Knockout page
  public KNOCKOUT_FORM_PAGE_HEADING = "Let's get some quotation for you";
  public KNOCKOUT_LETS_GET_STARTED = "Let’s get started.";
  public KNOCKOUT_FORM_PAGE_HEADING_JWL = "Let's secure your precious possession";
  public KNOCKOUT_FORM_PAGE_SUBHEADING = "First, please answer following brief questions to get a quote!";
  public CHOOSE_A_PLAN = "Choose a plan";
  public SELECT_PAYMENT_FREQUENCY = "Select your payment frequency";

  // Application Page
  public APPLICATION_PAGE_DESCRIPTION_PRIMARY = "Don't worry, all your secrets are safe with us!";
  public APPLICATION_PAGE_DESCRIPTION_SECONDARY =
    "Please answer all the questions to the best of your knowledge (* marked fields are mandatory).";
  public APPLICATION_PAGE_SELECTED_INSURANCE = "Selected Insurance";
  public APPLICATION_PAGE_QUOTES_FROM_INSURER = "Quotes from Insurer";
  // Quotes Page
  public QUOTE_PAGE_DESCRIPTION_PRIMARY = "Our prices will make your wallet do the happy dance!";
  public QUOTE_PAGE_DESCRIPTION_SECONDARY = "Please make sure all details are in order";
  public QUOTE_PAGE_COVERAGES = "Coverage";
  public QUOTE_PAGE_COVERAGE_LIMIT = "Limit:";
  public QUOTE_PAGE_COVERAGE_DEDUCTIBLE = "Deductible:";
  public QUOTE_PAGE_SELECT_ONE_PLAN = "(Select a plan to view its coverage details)";
  public QUOTE_PAGE_SUMMARY = "Summary";
  public QUOTE_PAGE_SEND_QUOTES = "Send Quotes";
  public QUOTE_PAGE_SEND_PROPOSAL = "Send proposal";
  public QUOTE_PAGE_DOWNLOAD_QUOTE_PDF = "Download quote pdf";
  public QUOTE_PAGE_SHARE_LINK = "Share Link";
  public QUOTE_PAGE_SUCCESS_MESSAGE = "Quotes sent successfully!";
  // Quotes Snapshot Page
  public QUOTES_SNAPSHOT_DESCRIPTION_PRIMARY = (applicantName: string) => {
    return `Hi ${applicantName} here is your quote`;
  };
  public QUOTES_SNAPSHOT_DESCRIPTION_SECONDARY =
    "The estimated premium that you have received is based on the types of policies you have selected and the information you have given us.";
  public QUOTES_SNAPSHOT_MAKE_PAYMENT = "Make Payment";
  public QUOTES_SNAPSHOT_PAYMENT_DONE = "Paid";
  public QUOTES_SNAPSHOT_PENDING_PAYMENT_STATUS = "Pending Completion";
  public QUOTES_SNAPSHOT_PAID_PAYMENT_STATUS = "Payment Complete";
  public QUOTES_TOTAL_PREMIUM = "Total premium";
  public QUOTES_TOTAL_PREMIUM_JWL = "Your premium";
  // Payment Page
  public PAYMENT_PAGE_TOTAL_DUE = "Total Due";
  public PAYMENT_PAGE_BREAKDOWN = "Breakdown";
  public PAYMENT_PAGE_INSURANCE = "Insurance";
  public PAYMENT_PAGE_INSURER = "Insurer";
  public PAYMENT_PAGE_PREMIUIM = "Premium";
  public PAYMENT_PAGE_BASIC_PREMIUM = "Basic Premium";
  public PAYMENT_PAGE_PREMIUIM_WITHOUT_VAT = "Premium (without VAT)";
  public PAYMENT_PAGE_TAX = "Tax";
  public PAYMENT_PAGE_TAX_JWL = "Tax incl. Surplus Lines tax and state fees";
  public PAYMENT_PAGE_GST = "GST";
  public PAYMENT_PAGE_DESCRIPTION_PRIMARY = "Best price, tailored for you";
  public PAYMENT_PAGE_DESCRIPTION_SECONDARY = "Please click on 'Make Payment' button to process";
  public PAYMENT_PAGE_TRANSACTION_FEE = "Transaction Fee";
  public CHECKOUT_FORM_AUTO_RENEWAL_CONSENT = "Auto-renew is";
  public PAYMENT_PAGE_SECURE_PAYMENT = "Secure Payment";
  public PAYMENT_PAGE_MAKE_PAYMENT = "Make Payment";
  public PAYMENT_PAGE_RETRY_PAYMENT = "Retry Payment";
  public PAYMENT_PAGE_SUCCESS_MESSAGE = "Payment successful!";
  public PAYMENT_PAGE_FAILURE_MESSAGE = "Payment failed!";
  public PAYMENT_COMPLETE = "Payment Complete";
  public PAYMENT_PENDING = "Pending Completion";
  public PROCESSING_PAYMENT = "Processing payment...";
  public DO_NOT_CLOSE_WINDOW = "Please do not close the window";
  // Checkout Form
  public CHECKOUT_FORM_CARD = "Card";
  public CHECKOUT_FORM_NAME_ON_CARD = "Name on the card";
  public CHECKOUT_FORM_CARD_NUMBER = "Card Number";
  public CHECKOUT_FORM_CARD_EXPIRY = "Card Expiry";
  public CHECKOUT_FORM_CVV = "CVV";
  public CHECKOUT_FORM_EMAIL = "E-mail";
  // Settings Page
  public SETTINGS_PAGE_DESCRIPTION_PRIMARY = "Need to update some info?";
  public SETTINGS_PAGE_TAB_COMPANY = "Company";
  public SETTINGS_PAGE_TAB_AGENCY = "Agency";
  public SETTINGS_PAGE_TAB_PERSONAL = "Personal";
  public SETTINGS_PAGE_TAB_CHANGE_PASSWORD = "Change Password";
  // Company Form
  public COMPANY_FORM_COMPANY_NAME = "Company Name";
  public COMPANY_FORM_CNPJ = "CNPJ";
  public COMPANY_FORM_REGISTRATION_NUMBER = "Registration Number";
  public COMPANY_FORM_DATE_OF_INCORPORATION = "Date of Incorporation";
  public COMPANY_FORM_COMPANY_NAME_REQUIRED = "Company Name is required";
  public COMPANY_FORM_CNPJ_REQUIRED = "CNPJ is required";
  public COMPANY_FORM_REGISTRATION_NUMBER_REQUIRED = "Registration Number is required";
  public COMPANY_FORM_DATE_OF_INCORPORATION_REQUIRED = "Date of Incorporation is required";
  // Personal Form
  public PERSONAL_FORM_FULL_NAME = "Full Name";
  public PERSONAL_FORM_CPF = "CPF";
  public PERSONAL_FORM_EMAIL = "Email";
  public PERSONAL_FORM_PHONE = "Phone";
  public PERSONAL_FORM_FULL_NAME_REQUIRED: "Full name is required";
  public PERSONAL_FORM_INVALID_PHONE = "Enter a valid phone number(11 digits)";
  public PERSONAL_FORM_PHONE_REQUIRED = "Phone is required";
  // Change Password
  public CHANGE_PASSWORD_FORM_NEW_PASSWORD = "New Password";
  public CHANGE_PASSWORD_FORM_CONFIRM_PASSWORD = "Confirm Password";
  public CHANGE_PASSWORD_SUBMIT_BUTTON = "Change Password";
  public CHANGE_PASSWORD_NEW_PASSWORD_REQUIRED = "New Password is required";
  public CHANGE_PASSWORD_CONFIRM_PASSWORD_REQUIRED = "Confirm Password is required";
  public CHANGE_PASSWORD_CONFIRM_PASSWORD_INVALID = "Passwords must match";
  public SOMETHING_WENT_WRONG = "Something went wrong";
  public NO_DATA_AVAILABLE = "No data available";
  public POP_UPS = {
    BACK_TO_DASHBOARD_POPUP: {
      title: "Cancel",
      content: "Would you like to cancel your quote?",
      btnText: "Confirm",
    },
    LOGOUT_POPUP: {
      title: "Logout",
      content: "Would you like to log out of your account?",
      btnText: "Confirm",
    },
    SEND_QUOTES_POPUP: {
      title: "Send Quote",
      content: "Have you reviewed plans for all selected products and confirmed your choices?",
      btnText: "Send",
    },
    SEND_PROPOSAL_POPUP: {
      title: "Send proposal",
      content: "Have you reviewed plans for all selected products and confirmed your choices?",
      btnText: "Send",
    },
  };
  public PASSWORD_REQ = {
    title: "Password requirements:",
    digit: "At least one digit",
    lowerCase: "At least one lowercase letter",
    upperCase: "At least one uppercase letter",
    specialChar: "At least one special character",
    minLength: "At least contain 10 characters",
  };
  public QUOTES_PAYMENT_FREQUENCY = {
    monthly: "Per Month",
    yearly: "Per Year",
  };
  public QUOTES_NOT_GENERATED = {
    title: `We couldn't find the right plans based on your application.`,
    subTitle: "Please try creating a new one.",
    reason: "Reason",
  };
  public SUCCESS_PAGE_CONTENT = {
    line1: "You will receive a confirmation email soon.",
    line2: "If you don't receive an email within the next two hours, or have any questions, please contact us at ",
    email: "quotes@brokery.io ",
    line3: "Thank you!",
    msigLine2: "For any questions or inquiries, please contact MSIG at ",
  };
  public ERROR_PAGE_CONTENT = {
    line1: "Error while sending quotes",
  };
  public CHATBOX = {
    heading: "We're here to help!",
    hello: "Hello!",
    helpText: "We are here to help you.",
    assistText: "Could you provide us with your email address and a brief description of your question?",
    email: "Email",
    message: "Message",
    submitQueryButtonText: "Submit Query",
    submitAnotherQueryButtonText: "Submit Another Query",
    thanksText: "Thank you for submitting your query!",
    thanksDescText: `We've received your information and will get back to you
    as soon as possible. If you have any further questions, feel
    free to submit another query.`,
    greatDayText: "Have a great day!",
    requiredText: "Required",
  };
  public FILTERS = {
    fromDate: "From Date",
    toDate: "To Date",
    filter: "Filter",
    insuranceProducts: "Insurance Products",
    search: "Search",
    allSelected: "All Selected",
    selectAll: "Select All",
    clearAll: "Clear All",
    hide: "Hide",
    apply: "Apply",
    selected: "Selected",
    noOptions: "No Options",
  };
  public TABLES = {
    action: "Action",
    view: "View",
    rowsPerPage: "Rows per page",
    of: "of",
    moreThan: "more than",
  };

  /**
   * Auth screens
   */
  public EMAIL = "Email";
  public PASSWORD = "Password";
  public FORGOT_PASSWORD_LINK = "Forgot Password?";
  public FORGOT_PASSWORD_DESCRIPTION = "Enter your registered email to register a new password";
  public VERIFY_EMAIL = "Verify E-Mail";
  public VERIFY_EMAIL_INFO = "Great! We've sent a verification email on you registered email address";
  public VERIFY_EMAIL_SUB_TEXT =
    "Please check your inbox (or spam folder) and click the link to confirm your account. Once verified, you can log in and start using application";
  public AVOID_LOCKED_OUT = "Avoid getting locked out.";
  public SENT_PASS_RECOVERY_INSTRUCTIONS = "We have sent a password recovery instructions to your email.";
  public VERIFY_USER_TO_PROCEED = "Please verify user to proceed";
  public RESET_PASSWORD = "Reset Password";
  public RESEND_EMAIL = "Resend Email";
  public CHOOSE_NEW_PASSWORD = "Please choose new password";
  public CONFIRM_PASSWORD = "Confirm Password";
  public RESET_PASSWORD_SUCCESSFUL = "Password Reset Successful";
  public RESET_PASSWORD_SUCCESSFUL_MESSAGE = "You can now use your new password to log in to your account";
  public LOGIN = "Log In";

  /**
   * Layout Translations
   */
  public MY_POLICIES = "My Policies";
  public ACCOUNT = "Account";
  public CHOOSE_LANGUAGE = "Choose Language";
  public FOOTER_TEXT = "© 2023 Stere, Inc.  |  All Rights Reserved";
  public TERMS = "Terms";
  public PRIVACY = "Privacy";
  public LICENSE = "Licenses";
  public WELCOME = "Welcome {Name}";
  public WELCOME_WITHOUT_NAME = "Welcome";

  /**
   * Policy Listings
   */
  public POLICY_TERMS = "Policy Term";
  public INSURER = "Insurer";
  public BROKER_ON_RECORD = "Broker on Record";
  public ACTIVE = "Active";
  public INACTIVE = "Inactive";

  /**
   * Policy Details
   */
  public COVERAGE_DETAILS = "Coverage Details";
  public COVERAGE = "Coverage";
  public CANCEL_POLICY = "Cancel Policy";
  public RENEW_POLICY = "Renew Policy";
  public POLICY = "Policy";
  public DOCUMENTS = "Documents";
  public AGENT_INFO = "Agent info";
  public CONTACT_AGENT = "Questions or concerns? Contact the agent for assistance.";

  /**
   * Account page
   */
  public CONTACT_INFO = "Contact Information";
  public NAME = "Name";
  public PHONE_NUMBER = "Phone Number";

  // Payment frequency
  public MONTHLY = "Monthly";
  public QUARTERLY = "Quarterly";
  public HALF_YEARLY = "Half yearly";
  public YEARLY = "Yearly";
  public BI_ANNUAL = "Bi-annual";
  public SUM_ASSURED = "Sum assured";

  public CONNECT_TO_SERVICE_USER = "Connect To Service User";
  public SELECT = "Select";

  public BEST = "Best";
  public SELLER = "Seller";

  public OR = "or";

  public NOTIFICATION_PAGE = {
    title: "All Notifications",
    accept: "Accept",
    decline: "Decline",
  };
  public FIRST_NAME = "First Name";
  public LAST_NAME = "Last Name";

  public FIRST_NAME_REQUIRED = "First name is required";
  public LAST_NAME_REQUIRED = "Last name is required";

  public PASSWORD_VALIDATION = {
    length: "Password must be at least 8 characters long",
    numericChar: "Password must contain at least one number",
    upperCaseLetter: "Password must contain at least one uppercase letter",
    lowerCaseLetter: "Password must contain at least one lowercase letter",
    specialCharacter: "Password must contain at least one special character",
  };

  // Login page errors
  public LOGIN_REQUIRED_FIELD_MISSING = "Invalid Data or Required Fields are missing";
  public LOGIN_TOKEN_NOT_FOUND = "Invalid FFID token Or FF User not found for the provided FFID token";
  public EXTERNAL_API_ISSUE = "There is an issue with the external API, please try again later";

  // Redirect
  public REDIRECT_TO_SOURCE = "Please wait, taking you to your destination...";

  // verification registration page
  public DONE_VERIFICATION = "Done with verification please login to continue";
  public VERIFYING_USER = "Verifying user...";
  public USER_ALREADY_REGISTERED = "User is already registered, please login";
  public DONE_VERIFICATION_ALERT = "User verification complete, Please login to continue.";

  // Agency details page
  public AGENCY_NAME = "Agency Name";
  public TAX_ID = "Tax ID (CNPJ)";
  public AGENCY_ADDRESS = "Agency Address";
  public LICENSE_NUMBER = "Licence No. (OPTIONAL)";
  public AGENCY_DETAILS = "Agency Details";

  // verify email confirmation
  public PLEASE_CHECK_YOUR_EMAIL = "Please check your Email";
  public UNABLE_TO_RESEND_EMAIL = "Unable to resend email";
  public VERIFY_EMAIL_GET_STARTED = "Verify Your Email to Get Started!";
  public SENT_VERIFICATION_EMAIL = "We've send a verification email to";
  public ENSuRE_ACCOUNT_SECURITY_MESSAGE =
    "To ensure your account security and unlock the full features of the app, please click the link in the email to confirm registration";
  public CHECK_SPAM_FOLDER = "Can't find it? Check your spam folder";
  public YOUR_EMAIL = "your email";
  public USER_NOT_EXIST = "This user doesn't exist. Please register first.";
  public INVITATION_SENT = "Invitation sent";

  public NO_SUFFICIENT_PERMISSION = "You don't have sufficient permission";
  public INVITE_ALREADY_ACCEPTED = "Invite already accepted";
  public INVALID_AUTH_KEY = "Please login first";
  public REGISTER_OR_LOGIN_BEFORE_ACCEPTING_INVITE =
    "Please register if you are a new user or login before accepting the invite";
  public INVITE_ACCEPTED = "Invite accepted";
  public ACCEPTING_INVITE = "Accepting invite";
  public NO_RECORD_FOUND = "No records found";

  public CNPJ_IS_REQUIRED = "CNPJ is required";
  public INVALID_CNPJ_FORMAT = "Invalid CNPJ format";
  public INVALID_CNPJ = "Invalid CNPJ";
  public AGENCY_NAME_IS_REQUIRED = "Agency Name is required";
  public AGENCY_ADDRESS_IS_REQUIRED = "Agency Address is required";
  public INVALID_MEMBER_ID = "Invalid member id";
  public MEMBER_DELETED_SUCCESSFULLY = "Member deleted successfully";
  public NO_NEW_NOTIFICATION_AVAILABLE = "No new notification";
  public UPDATE = "Update";
  public ACCOUNT_UPDATED = "Account updated";
  public PROFILE_UPDATE = "Profile updated";

  public VERIFY_EMAIL_LABEL = "Verify E-Mail";
  public AVOID_GETTING_LOCKED_OUT = "Avoid getting locked out.";
  public PASSWORD_RECOVERY_INSTRUCTIONS_SENT = "We have sent password recovery instructions to your email.";
  public PASSWORD_RECOVERY_INSTRUCTIONS_SENT_TOAST = "Password recovery instructions sent to your email";

  public DONE_RESETTING_PASSWORD = "Done resetting password, please login";
  public INVALID_TOKEN = "Invalid token";
  public CONFIRM_PASSWORD_REQUIRED = "Confirm Password is required";
  public PASSWORDS_MUST_MATCH = "Passwords must match";
  public SUBMIT = "Submit";
  public NO_APPOINTMENT_AVAILABLE = "No appointments available";

  public FILTER_APPOINTMENTS = "Filter appointments...";
  public STATUS = "Status";
  public VALIDITY = "Validity";
  public UPCOMING = "Upcoming";
  public OUTDATED = "Outdated";
  public PENDING = "Pending";
  public APPROVED = "Approved";
  public REJECTED = "Rejected";

  public GO_BACK = "Go back";
  public REQUEST_APPOINTMENT = "Request appointment";

  public PRODUCT = "Product";
  public BROKERY_NAME = "Brokery Name";
  public CREATED_AT = "Created At";

  public INSURER_LABEL = "Insurer";
  public CONFIRM_SUBMISSION_TITLE = "Confirm submission";
  public CONFIRM_SUBMISSION_MESSAGE = "Are you sure you want to submit your appointment request?";
  public INSURER_REQUIRED = "Insurer is required";
  public PRODUCT_REQUIRED = "Product is required";

  public NO_RESULTS_FOUND = "No results found";

  public ERROR_FETCHING_INSURER = "Something went wrong while fetching insurer details";

  public ERROR_LOADING_INSURER_PRODUCTS_DATA = "Something went wrong while loading insurer products data";
  public ERROR_LOADING_INSURER_DATA = "Something went wrong while loading insurer data";
  public APPOINTMENT_ALREADY_EXISTS = "Appointment already exists";

  public USER_ROLE_IS_REQUIRED = "User role is required field";
  public I_AM_ADMIN = "I am admin";
  public I_AM_AGENT = "I am agent";
  public CPF = "CPF";
  public CPF_IS_REQUIRED = "CPF is required";

  public TO_ACCESS_ALL_FEATURES = "To access all features, you need to be part of a broker account";
  public BROKER_ACCOUNT_ALREADY_EXISTS = "Broker account already exists";
  public BROKER_ACCOUNT_WITH_SAME_CNPJ_EXISTS =
    "A broker account with the same CNPJ already exists. You can request access from the existing account administrator or enter a valid CNPJ.";
  public ACCOUNT_DETAILS = "Account details";
  public ADMIN = "Admin";
  public AGENCY = "Agency";
  public BACK_TO_LOGIN = "Back to login";
  public REQUEST_TO_JOIN_ACCOUNT = "Request to join account";
  public CREATE_BROKER_ACCOUNT = "Create a Broker account";
  public BECOME_ADMIN_AND_BUILD_TEAM = "Become an admin and build your team by creating your own broker account.";
  public NAVIGATE_TO_PROFILE = "To do so, navigate to your profile section and add your broker agency's details.";
  public MY_PROFILE = "My Profile";
  public REQUEST_ACCEPT_INVITE = "Request / Accept an Invite";
  public CHECK_NOTIFICATION_CENTER = "Please check your notification center for any pending invitations.";
  public REQUEST_JOIN_BROKER_ACCOUNT = "Request to join a broker account by entering the admin's email address.";
  public EMAIL_ADDRESS = "Email address";
  public SEND_REQUEST = "Send request";
  public NOTIFICATION_CENTER = "Notification center";
  public GET_FULL_ACCESS = "Get full access";
  public ADD_AGENCY_DETAILS = "Add Agency Details";
  public MEMBERSHIP_REQUEST_SENT = "Membership Request Sent";
  public ADMIN_NOT_FOUND = "Admin Not Found";
  public MEMBERSHIP_ALREADY_REQUESTED = "Membership Already Requested";
  public INVITES = "Invites";
  public MEMBERSHIP_REQUEST = "Membership Request";
  public IS_REQUESTING_TO_JOIN = "is requesting to join";
  public USER_IS_ALREADY_MEMBER = "User is already a member";
  public MEMBERSHIP_REQUEST_APPROVED = "Membership request approved";
  public MEMBERSHIP_REQUEST_REJECTED = "Membership request rejected";
  public INVALID_MEMBERSHIP_ID = "Invalid membership ID";
  public PROCEEDING_WILL_SEND_REQUEST =
    "Proceeding will send a request to the admin to join, and your user profile will be marked as an agent of that account.";
  public REQUEST_TO_JOIN = "Request to Join";
  public CANCELLED = "Cancelled";
  public EXPIRED = "Expired";

  public NO_ASSIGNED_APPOINTMENT =
    "You don't have any assigned appointment. Please go to request appointment to proceed";
  public CLOSE = "Close";
  // Proposal Flow
  public ACCEPT_PROPOSAL = "Accept proposal";
  public PROPOSAL_ACCEPTED = "Proposal accepted";
  public READ_TERMS = "Read and mark the terms below to proceed with taking out your insurance";
  public LINK_EXPIRED = "This link is no longer available. You have already approved this proposal/document.";

  // Payment Flow
  public PAYMENT_DETAILS_NOT_FOUND = "Payment Details not found!";
  public PAYMENT_NOT_SUPPORTED = "Payment not supported!";
  public PAYMENT_APPROVED = "Payment Approved!"
  public UNKNOWN_PAYMENT_STATUS = "Unknown Payment Status!";
  public QR_CODE_NOT_AVAILABLE = "QR Code not available!";
  public QR_CODE_EXPIRED = "QR Code Expired!"
  public EXPIRES_AT = "Expires At";
  public SCAN_TO_PAY_MESSAGE = "Scan to pay with your bank app. If already paid please refresh the page.";
  public COPY_LINK_MESSAGE = "Copy link to share";
  public PASTE_PIX_CODE_MESSAGE = "Paste the Pix code under 'Pix copy and paste' in your internet banking portal or banking app.";
}

export default TranslationEn;
