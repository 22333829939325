import CreateNewPassword from "components/CreateNewPassword/CreateNewPassword";
import Applications from "components/Home/Applications/Applications";
import Dashboard from "components/Home/Dashboard/Dashboard";
import Home from "components/Home/Home";
import Policies from "components/Home/Policies/Policies";
import PolicyPdfViewer from "components/Home/Policies/PolicyPdfViewer";
import Quotes from "components/Home/Quotes/Quotes";
import Notifications from "components/Notifications/Notifications";
import NewQuote from "components/Quote/NewQuote";
import Application from "components/Quote/sections/Application";
import Confirmation from "components/Quote/sections/Confirmation";
import CustomerDetails from "components/Quote/sections/CustomerDetails/CustomerDetails";
import Payment from "components/Quote/sections/Payment";
import Quote from "components/Quote/sections/quote/Quote";
import Start from "components/Quote/sections/start/Start";
import Submission from "components/Submission";
import FullScreenLoader from "components/loader/FullScreenLoader";
import GlobalLoader from "components/loader/GlobalLoader";
import { CORX, IS_BROKER_PORTAL, IS_HOSTED_INSURANCE, PORTAL_NAME } from "config";
import { useFlags } from "flagsmith/react";
import { AnimatePresence } from "framer-motion";
import OnBoarding from "modules/Accounts/OnBoarding";
import AcceptMemberInvite from "modules/Agents/AcceptMemberInvite";
import Agents from "modules/Agents/Agents";
import useIsAdmin from "modules/Agents/useIsAdmin";
import AppointmentsPage from "modules/Appointments/AppointmentsPage";
import Login from "modules/Auth/Login";
import Register from "modules/Auth/Register";
import ResetPassword from "modules/Auth/ResetPassword";
import VerifyEmail from "modules/Auth/VerifyEmail";
import VerifyRegistration from "modules/Auth/VerifyRegistration";
import useAccountsInfo from "modules/Auth/useAccountsInfo";
import useAvailableProducts from "modules/Appointments/useAvailableAppointments";
import useRequestedMembersInfo from "modules/Auth/useRequestedMembersInfo";
import UserProfileSettings from "modules/Profile/UserProfileSettings";
import {
  defaultProductsSelector,
  portalConfigSelector,
  xApiKeySelector,
} from "portalConfig/selectors";
import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  fetchAvailableProducts,
  saveClientSentInfo,
} from "store/features/clientSlice";
import { authSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import AuthPageRoute from "utils/AuthPageRoute";
import ProtectedRoute from "utils/ProtectedRoute";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";
import Settings from "views/settings/Settings";
import RequestResetPassword from "modules/Auth/RequestResetPassword";

const KnockoutPage = lazy(
  () => import("components/Quote/sections/Knockout/KnockoutPage")
);

const ApplicationQuotesComponent = lazy(
  () => import("views/application/quotes")
);

const ReviewQuotes = lazy(
  () => import("views/application/quotes/ReviewQuotes")
);


const MakePayment = lazy(
  () => import("views/payments/Index")
);

function AppRoutes() {
  const { isAccountsLoading } = useAccountsInfo();
  const { isRequestMemberListLoading } = useRequestedMembersInfo();
  const { isAppointmentsLoading } = useAvailableProducts();
  const location = useLocation();
  const xApiKey = useSelector(xApiKeySelector);
  const defaultProducts = useSelector(defaultProductsSelector);
  const { portalAccount } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { loading: isConfigLoading } = useSelector(portalConfigSelector);

  const {
    v1_dashboard,
  } = useFlags([
    "v1_dashboard",
  ]);

  const { isFlagSmithLoading } = useSelector(
    (state: RootState) => state.flagSmith
  );
  const isAdmin = useIsAdmin();
  const isAppLoading = isFlagSmithLoading || isAccountsLoading || isRequestMemberListLoading || isAppointmentsLoading;

  useEffect(() => {
    if (isAppLoading) {
      return;
    }
    if (!xApiKey) return;
    if (!getFromLocalStorage(LSKeys.ACCESS_TOKEN)) {
      localStorage.clear();
    }

    if (portalAccount || getFromLocalStorage(LSKeys.ACCESS_TOKEN)) {
      let applicantID = localStorage.getItem("currentApplicantID");
      if (!applicantID) {
        applicantID = `applicant_${new Date().getTime()}`;
        localStorage.setItem("currentApplicantID", applicantID);
      }
      // This is temporary need to change after this release
      dispatch(
        saveClientSentInfo({
          "X-API-KEY": xApiKey,
          applicant_id: applicantID,
          product_selection: defaultProducts,
        })
      );
      if (PORTAL_NAME !== CORX) {
        dispatch(fetchAvailableProducts());
      }
    }
  }, [portalAccount, defaultProducts, xApiKey, dispatch, isAppLoading]);

  if (isAppLoading) {
    return <FullScreenLoader />;
  }

  if ((isConfigLoading && !IS_HOSTED_INSURANCE) || isAppLoading) {
    return <GlobalLoader />;
  }

  return (
    <AnimatePresence mode="sync">
      <Routes location={location} key={location.key}>
        {/* Auth routes */}
        <Route
          path="/"
          element={
            <AuthPageRoute>
              <Login />
            </AuthPageRoute>
          }
        ></Route>
        <Route
          path="/login"
          element={
            <AuthPageRoute>
              <Login />
            </AuthPageRoute>
          }
        ></Route>
        <Route
          path="/forgot-password"
          element={
            <AuthPageRoute>
              <RequestResetPassword />
            </AuthPageRoute>
          }
        ></Route>
        <Route
          path="/register"
          element={
            <AuthPageRoute>
              <Register />
            </AuthPageRoute>
          }
        ></Route>
        <Route
          path="/verifyEmail"
          element={
            <AuthPageRoute>
              <VerifyEmail />
            </AuthPageRoute>
          }
        ></Route>
        <Route
          path="/reset-password/:token"
          element={
            <AuthPageRoute>
              <ResetPassword />
            </AuthPageRoute>
          }
        ></Route>
        <Route
          path="/verify-registration/:encodedInfo"
          element={<VerifyRegistration />}
        ></Route>
        <Route
          path="/accept-invite/:memberId"
          element={<AcceptMemberInvite />}
        ></Route>
        <Route path="/notifications" element={<Notifications />}></Route>
        <Route
          path="/create-new-password"
          element={<CreateNewPassword />}
        ></Route>
        <Route path="/review_quote" element={<ReviewQuotes />} />
        <Route path="/make_pament" element={<MakePayment />} />
        <Route
          path="/application/payment"
          element={<ApplicationQuotesComponent />}
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          {v1_dashboard.enabled && <Route path="/home/dashboard" element={<Dashboard />} />}
          <Route path="/home/applications" element={<Applications />} />
          <Route path="/home/quotes" element={<Quotes />} />
          <Route path="/home/quotes/:submissionId" element={<Submission />} />
          <Route path="/home/policies" element={<Policies />} />
          <Route
            path="/home/notifications"
            element={<Notifications />}
          ></Route>
          <Route
            path="/home/onboarding"
            element={
              <ProtectedRoute>
                <OnBoarding />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/home/appointments"
            element={
              <ProtectedRoute>
                <AppointmentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home/profile"
            element={
              <ProtectedRoute>
                <UserProfileSettings />
              </ProtectedRoute>
            }
          ></Route>
          {isAdmin && (
            <Route path="/home/agents" element={<Agents />}></Route>
          )}
          <Route
            path="/home/policies/:policyId"
            element={<PolicyPdfViewer />}
          />
          <Route path="/home/claims" element={<Policies />} />
          <Route path="/home/settings" element={<Settings />} />
        </Route>
        <Route
          path="/newQuote"
          element={
            <ProtectedRoute>
              <NewQuote />
            </ProtectedRoute>
          }
        >
          <Route path="/newQuote/start" element={<Start />}></Route>
          <Route
            path="/newQuote/customerDetails"
            element={<CustomerDetails />}
          ></Route>
          <Route path="/newQuote/application" element={<Application />}></Route>
          <Route path="/newQuote/quote" element={<Quote />}></Route>
          {IS_HOSTED_INSURANCE ? (
            <>
              <Route path="/newQuote/payment" element={<Payment />}></Route>
              <Route
                path="/newQuote/confirmation"
                element={<Confirmation />}
              ></Route>
            </>
          ) : null}
        </Route>
        {IS_HOSTED_INSURANCE ? (
          <Route
            path="/knockout"
            element={
              <ProtectedRoute>
                <KnockoutPage />
              </ProtectedRoute>
            }
          ></Route>
        ) : null}
        {/* Note: This is for redirection from incorrect path */}
        {IS_BROKER_PORTAL ? <Route path="*" element={<Navigate to="/home/applications" replace />} /> : null}
      </Routes>
    </AnimatePresence>
  );
}

export default AppRoutes;
