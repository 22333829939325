import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DocuSeal from "components/DocuSeal/DocuSeal";
import CustomButton from "components/common/CustomButton/CustomButton";
import { IS_BROKER_PORTAL } from "config";
import { STERE_CHECKOUT_COMPLETED } from "constants/webhookEvents";
import { fireWebhookEvent } from "store/features/webhooksSlice";
import { clientSelector, quotesSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { sendMessageToClient } from "utils/utils";
import EsignSuccess from "./EsignSuccess";
import SuccessMessage from "./SuccessMessage";

const Confirmation = () => {
  const { t } = useT();
  const dispatch = useDispatch();
  const { submissionId, siaApplicationId, updatedSiaApplication } = useSelector(
    (state: RootState) => state.surveyJs
  );
  const { selectedQuotes } = useSelector(quotesSelector);
  const { applicantId, selectedProducts } = useSelector(
    (state: RootState) => state.client
  );
  const [showDocusealSuccess, setShowDocusealSuccess] = useState(false);
  const [customMessage, setCustomMessage] = useState<string>(null);
  const { clientUrl } = useSelector(clientSelector);
  const { autoRenewalConsent, showAutoRenewalSwitch } =
    useSelector(clientSelector);
  const isAutoRenewalActivated = showAutoRenewalSwitch || false;

  useEffect(() => {
    if (!IS_BROKER_PORTAL) {
      dispatch(
        fireWebhookEvent({
          event_type: STERE_CHECKOUT_COMPLETED,
          metadata: {
            applicantId: applicantId,
            applicationId: siaApplicationId,
            submissionid: submissionId,
            selectedQuotes: selectedQuotes?.map((q) => {
              return {
                quote_id: q.id,
                premium: {
                  currency: q.premium?.currency,
                  premium_value: q.premium?.premium_value,
                  tax: q.premium?.tax,
                },
                insurer_name: q?.product_details?.insurer,
              };
            }),
            payment: {
              status: "Success",
              transactionId: sessionStorage.getItem("tId"),
            },
            policyId: sessionStorage.getItem("policyId"),
            autoRenewal: {
              available: isAutoRenewalActivated,
              ...(isAutoRenewalActivated
                ? { enabled: autoRenewalConsent }
                : {}),
            },
          },
        })
      );
      sessionStorage.removeItem("tId");
    }
  }, []);

  useEffect(() => {
    if (selectedProducts.includes("HEALTH-HBA") || selectedProducts.includes("CONDO_HOA-MOUNTAINWEST")) {
      setCustomMessage(
        "Your application is under review. Please allow us a few days for the review, and we will contact you."
      );
    } else {
      setCustomMessage(null);
    }
  }, [selectedProducts]);

  const {clientFeatures} = useSelector(clientSelector);

  const RenderCustomMessage = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <Typography sx={{ fontSize: "1rem" }}>{customMessage}</Typography>
    </Box>
  );

  if (
    sessionStorage.getItem("template_slug") &&
    sessionStorage.getItem("template_slug") !== "undefined" &&
    !showDocusealSuccess
  ) {
    return (
      <DocuSeal
        email={updatedSiaApplication?.params?.contact_email}
        onComplete={() => setShowDocusealSuccess(true)}
        templateSlug={sessionStorage.getItem("template_slug")}
      />
    );
  }

  if (showDocusealSuccess) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* Note - need to make it configurable */}
        <EsignSuccess brokerEmail="onceinsurance-policies@brokery.io" />
        <Box sx={{ textAlign: "right", m: "20px" }}>
          <CustomButton
            onClickCB={() => {
              sendMessageToClient({ type: "EXIT", details: {} }, clientUrl);
            }}
            btnText={t("EXIT")}
          />
        </Box>
      </Box>
    );
  }

  if (customMessage) {
    return <RenderCustomMessage />;
  }
  if(clientFeatures?.name === "Epic Fleet Up") {
    return <SuccessMessage message={""} />;
  }

  return <SuccessMessage message={t("PAYMENT_PAGE_SUCCESS_MESSAGE")} />;
};

export default Confirmation;
